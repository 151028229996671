import React, { useState } from 'react';
import FAQImage from '../../assets/faq_background.png'; // Adjust the path as needed
import '../../styles/FAQstyle/FAQ.css';
import { Helmet } from 'react-helmet';
// import FAQcontact from './FAQcontact';

function FAQs() {
    const [openIndex, setOpenIndex] = useState(null); // Tracks which FAQ is open

    const toggleFAQ = index => {
        setOpenIndex(openIndex === index ? null : index);
    };

    const faqData = [
        {
            question: "Does Prime Tuition offer private tutoring?",
            answer: [
                "Yes! Our tutoring sessions are individually tailored one-on-one lessons, pairing each student with a tutor through a meticulous matching process that considers both academic needs and personal compatibility. Should the initial pairing not meet expectations we are prepared to make adjustments ensuring every student receives the most effective and enjoyable learning experience."
            ]
        },
        {
            question: "Who are the tutors at Prime?",
            answer: [
                "We only work with the best.",
                "That's why our tutors aren't just high achievers, but also serve as empathetic mentors, dedicated to motivating your child to boost their grades and reach their full academic potential.",
                "All Prime tutors undergo a rigorous selection process in which the minimum requirement for tutors is a band 6 or E4 in any subject they tutor and a minimum ATAR of 95 to ensure they can provide the highest quality of education."
            ]
        },
        {
            question: "What's included in the tutoring sessions?",
            answer: [
                "Prime Tuition offers personalised one-on-one lessons that can be conducted either face-to-face at the student’s home or a suitable public location, or virtually via platforms such as Zoom and Google Meet.",
                "Whether online or in person, our sessions are structured to be interactive and engaging, maximizing the academic success for the student.",
                "During sessions, tutors focus on the curriculum, essential skills, exam strategies, or any specific areas the student wishes to explore, all at a pace that is comfortable for them. To gauge progress and identify areas needing further attention, tutors periodically administer progress exams. Additionally, homework is assigned weekly to reinforce learning, although adjustments can be made if a student is already facing significant schoolwork demands, based on parental feedback."
            ]
        },
        {
            question: "How do you measure progress?",
            answer: [
                "Progress will be regularly assessed through termly quizzes and weekly homework assignments to reinforce learning, pracitice new concepts, and develop problem-solving skills. Additionally, we provide weekly session summaries to keep parents informed ",
                "Prime Tuition will also schedule monthly calls with parents to discuss their child's progress, address any concerns, and adjust the learning plan if necessary."
            ]
        },
        {
            question: "Do you provide tutoring the HSC?",
            answer: [
                "Absolutely, we specialise in preparing students for major exams such as the HSC, offering comprehensive coverage of all required materials and practice exams to ensure students are well-prepared.",
                "Prime has a number of high achieving tutors in subjects such as Maths (Standard, Advanced, Extension 1, and Extension 2), English (Standard, Advanced, Extension 1, and Extension 2), Biology, Physics, Chemistry, Economics, Legal Studies, and History"
            ]
        },
        {
            question: "How do I start?",
            answer: [
                "If you're interested in beginning tutoring sessions with Prime, click here to connect with our dedicated team. We can provide you with detailed information about our sessions, and find times and days that work best for you. We'll also take the opportunity to understand your goals and initiate the tutor-student matching process!"
            ]
        }
    ];

    return (
        <div className="faqs-page">
            <Helmet>
                <title>FAQs | Private Tutoring Sydney | Prime Tuition </title>
                <meta 
                    name="description" 
                    content="Have questions about private tutoring at Prime Tuition? Find answers to frequently asked questions about our one-on-one tutoring, the qualifications of our tutors, HSC preparation, and how to get started." 
                />
                <link rel="canonical" href="https://primetuition.org/FAQs" />
                <meta charset="utf-8" />
                <meta 
                    name="keywords" 
                    content="FAQs, tutoring questions, private tutoring, HSC tutoring, ATAR tutors, one-on-one tutoring, Prime Tuition, tutoring Sydney. questions, frequently asked questions, asked questions, tutors, tutoring session, progress, HSC" 
                />
            </Helmet>
            <div className="image-container-faq">
                <img src={FAQImage} alt="FAQs" className="faq-image" />
                <div className="overlay1"></div>
                <div className="overlay-text-faq">
                    <h1>Frequently Asked Questions</h1>
                </div>
            </div>
            <div className="faq-list">
                {faqData.map((faq, index) => (
                    <div key={index} className={`faq-item ${openIndex === index ? 'open' : ''}`} onClick={() => toggleFAQ(index)}>
                        <div className="faq-content">
                            <h2>
                                {faq.question}
                                <span className="dropdown-arrow">
                                    <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 1l5 5 5-5" stroke={openIndex === index ? '#fff' : '#0e1726'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </span>
                            </h2>
                            {openIndex === index && (
                                <div>
                                    {faq.answer.map((para, idx) => <p key={idx}>{para}</p>)}
                                </div>
                            )}
                        </div>
                    </div>
                ))}
            </div>
            {/* <FAQcontact /> */}
        </div>
    );
    
}

export default FAQs;
