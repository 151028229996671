// Testimonials.js
import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import '../../styles/homepage style/Testimonials.css';

function Testimonials() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 7500,
  };

  return (
    <div className="testimonials-container">
      <div className="testimonials-title">
        <h1>Reviews from Other Local Families</h1>
      </div>
      <Slider {...settings}>
        <div className="testimonial">
          <p>"Prime Tuition has completely changed how my daughter views Math. Her tutor's enthusiasm
            and expertise have sparked a real passion for the subject in her."</p>
          <span className="author">Keval</span>
          <span className="location">Sydney</span>
        </div>
        <div className="testimonial">
          <p>"Finding Prime was a game-changer. Their hands-on approach in Physics has turned my son's weaknesses
            into strengths and he looks forward to his sessions!"</p>
          <span className="author">Irene</span>
          <span className="location">Winston Hills</span>
        </div>
        <div className="testimonial">
          <p>"The one-on-one sessions at Prime have been brilliant for my daughter who was struggling with English.
            She's not only more confident but also pulling up her grades.

            Can't thank her tutor enough!"</p>
          <span className="author">Emma</span>
          <span className="location">Rockdale</span>
        </div>
        <div className="testimonial">
          <p>"I'm really impressed with with the quality of tutors at Prime. The tutor working with my son is super
            patient and supportive, helping him nail the tough stuff."</p>
          <span className="author">Suresh</span>
          <span className="location">Schofields</span>
        </div>
        <div className="testimonial">
          <p>"Prime really stands out with their tailored English tutoring. My child's learning and interest have skyrocketed,
            all thanks to their lessons."</p>
          <span className="author">Lori</span>
          <span className="location">Bondi</span>
        </div>
      </Slider>
    </div>
  );
}

export default Testimonials;
