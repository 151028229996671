// BenefitsSection.js
import React from 'react';
import '../../styles/homepage style/BenefitsSection.css';

function BenefitsSection() {
  return (
    <div className="benefits-section">
      <div className="benefit benefit1">
        <p>Boost confidence</p>
      </div>
      <div className="benefit benefit2">
        <p>Improve marks</p>
      </div>
      <div className="benefit benefit3">
        <p>Realise potential</p>
      </div>
    </div>
  );
}

export default BenefitsSection;
