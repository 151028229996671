import React from 'react';
import { Link } from 'react-router-dom';
import '../../styles/homepage style/Boost.css';
import oImage from "../../assets/online 4.png";
import pImage from "../../assets/in person 4.png";
import arrowIcon from "../../assets/icons/arrow.svg";

function BoostSection() {
  return (
    <section className="boost-section">
      <div className="boost-content">
        <h1>
          Elevate your <span className="highlight-purple">Grades</span> and build <span className="highlight-orange">Confidence</span> at school
        </h1>
        <p className="boost-desktop"> 
          Our goal at Prime is to help your child boost their marks, build confidence, and develop
          a love for learning. Based in Sydney Australia, we offer tailored, private tutoring for both primary and high school students with a focus on HSC success. Our dedicated 
          team of expert tutors is committed to guiding each student toward academic excellence and personal growth.
        </p>
      <p className="boost-mobile">
      At Prime, we are dedicated to helping your child achieve academic excellence. With a team of expert tutors, we offer private tutoring across all primary and high school subjects. </p>      </div>
      <div className="boost-images">
        <div className="image-container">
          <Link to="/face-to-face-tutoring">
            <img src={pImage} className="boost-image1" alt="Face-to-Face Tutoring" />
          </Link>
          <div className="image-text">
          <Link to="/face-to-face-tutoring" className='custom-link'>
          In Person Tutoring
            <img src={arrowIcon} alt="Arrow Icon" className="arrow-icon" />
            </Link>
          </div>
        </div>
        <div className="image-container">
          <Link to="/online-tutoring">
            <img src={oImage} className="boost-image2" alt="Online Tutoring" />
          </Link>
          <div className="image-text">
            <Link to="/online-tutoring" className='custom-link'>
          Online Tutoring
            <img src={arrowIcon} alt="Arrow Icon" className="arrow-icon" />
            </Link> 
          </div>
        </div>
      </div>
    </section>
  );
}

export default BoostSection;
