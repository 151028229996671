import React, { useState, useEffect } from 'react';
import '../../styles/subjectsstyle/Biology.css';
import biologyImage from '../../assets/subjects.jpg';


const Biology = () => {

  const [activeTab, setActiveTab] = useState('year11');

  const handleTabClick = (year) => {
    
    setActiveTab('');
    setTimeout(() => {
      setActiveTab(year);
    }, 0); // Adjust the delay as needed for a smoother transition
  };

  useEffect(() => {
    // Scroll to top on initial load
    window.scrollTo(0, 0);
  }, []);


  return (
  
    <div className="biology-overview-container">
      
      <div className="biology-image-container">
        <img src={biologyImage} alt="Subjects" className="biology-image" />
        <div className="overlay-biology"></div>
        <div className="overlay-text-biology">
          <h1>Biology</h1>
        </div>
      </div>
      <div class="biology-book">
  <div class="biology-book-content">
    <h2>Book Biology Tutoring</h2>
    <p className='biology-p'>
    At Prime, our dedicated tutors make Biology accessible and engaging by connecting students with Sydney-based tutors who foster a supportive learning environment. 
    We cover all Preliminary and HSC Biology modules, from cell biology to ecology, providing personalised resource folders with past exam papers, model answers, and 
    comprehensive study guides. </p>
    <a href="/book">
    <button>Book Tutoring</button>
    </a>
  </div>
</div>
      <div className='biology-statement'>
          <h1 className='biology-final'>“Understanding the intricate processes of life is both fascinating and rewarding. 
            I strive to inspire students to appreciate the complexity and beauty of Biology, making learning a fun and engaging experience.”</h1>
            <p className='biology-name'>Leo Li | Biology Tutor</p>
          </div>
      
      <div className='biology-bg'>
      <h1 className="biology-title">Course Overview</h1>
      <p className="biology-subtitle">Select tab to pick year</p>
      <div className="biology-tabs">
        <button className={`biology-tab-button ${activeTab === 'year11' ? 'active' : ''}`} onClick={() => handleTabClick('year11')}>Year 11</button>
        <button className={`biology-tab-button ${activeTab === 'year12' ? 'active' : ''}`} onClick={() => handleTabClick('year12')}>Year 12</button>
      </div>
      <div id="year11" className={`biology-tab-content ${activeTab === 'year11' ? 'active' : ''}`}>
        <div className="biology-timeline">
          <div className="biology-timeline-item left">
            <div className="biology-timeline-content">
              <h3>Cells as the Basis of Life</h3>
              <p>Cell Structure</p>
              <p>Cell Function</p>
              
            </div>
            <div className="biology-timeline-icon">
              <p>1</p>
            </div>
          </div>
          <div className="biology-timeline-item right">
            <div className="biology-timeline-content">
              <h3>Organisation of Living Things</h3>
              <p>Organisation of Cells</p>
              <p>Nutrient and Gas Requirements</p>
              <p>Transport</p>
             
            </div>
            <div className="biology-timeline-icon">
              <p>2</p>
            </div>
          </div>
          <div className="biology-timeline-item left">
            <div className="biology-timeline-content">
              <h3>Biological Diversity</h3>
              <p>Effects of the Environment on Organisms</p>
              <p>Adaptations</p>
              <p>Theory of Evolution by Natural Selection</p>
              <p>Evolution - The Evidence</p>
            </div>
            <div className="biology-timeline-icon">
              <p>3</p>
            </div>
          </div>
          <div className="biology-timeline-item right">
            <div className="biology-timeline-content">
              <h3>Ecosystem Dynamics</h3>
              <p>Population Dynamics</p>
              <p>Past Ecosystems</p>
              <p>Future Ecosystems</p>
            
            </div>
            <div className="biology-timeline-icon">
              <p>4</p>
            </div>
          </div>

        </div>
      </div>
      <div id="year12" className={`biology-tab-content ${activeTab === 'year12' ? 'active' : ''}`}>
        <div className="biology-timeline-12">
          <div className="biology-timeline-item left">
            <div className="biology-timeline-content">
            <h3>Heredity</h3>
              <p>Reproduction</p>
              <p>Cell Replication</p>
              <p>DNA and Polypeptide Synthesis</p>
              <p>Genetic Variation</p>
              <p>Inheritance Patterns in a Population</p>
            </div>
            <div className="biology-timeline-icon">
              <p>1</p>
            </div>
          </div>
          <div className="biology-timeline-item right">
            <div className="biology-timeline-content">
            <h3>Genetic Change</h3>
              <p>Mutation</p>
              <p>Biotechnology</p>
              <p>Genetic Technologies</p>
             
            </div>
            <div className="biology-timeline-icon">
              <p>2</p>
            </div>
          </div>
          <div className="biology-timeline-item left">
            <div className="biology-timeline-content">
              <h3>Infectious Disease</h3>
              <p>Causes of Infectious Disease</p>
              <p>Responses to Pathogens</p>
              <p>Immunity</p>
              <p>Quantum Model of Light</p>
              <p>Prevention, Treatment and Control</p>
            </div>
            <div className="biology-timeline-icon">
              <p>3</p>
            </div>

          </div>
          <div className="biology-timeline-item right">
            <div className="biology-timeline-content">
              <h3>Non-infectious Disease and Disorders</h3>
              <p>Homeostasis</p>
              <p>Causes and Effects</p>
              <p>Epidemiology</p>
              <p>Prevention</p>
              <p>Technologies and Disorders</p>
            </div>
            <div className="biology-timeline-icon">
              <p>4</p>
            </div>
          </div>
          </div>
        </div>
      </div>
     
    </div>
  );
};

export default Biology;