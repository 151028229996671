import React, { useState, useEffect } from 'react';
import '../../styles/subjectsstyle/English.css';
import englishImage from '../../assets/subjects.jpg';

const English = () => {

  useEffect(() => {
    // Scroll to top on initial load
    window.scrollTo(0, 0);
  }, []);

  const [activeMainTab, setActiveMainTab] = useState('eng_standard');
  const [activeSubTab, setActiveSubTab] = useState('year11');

  const handleMainTabClick = (mainTab) => {
    setActiveMainTab(mainTab);
    setActiveSubTab(mainTab === 'extension2' ? 'year12' : 'year11'); // Reset to year12 for extension2
  };
  

  const handleSubTabClick = (subTab) => {
    setActiveSubTab(subTab);
  };

  const renderContent = () => {
    if (activeMainTab === 'eng_standard') {
      if (activeSubTab === 'year11') {
        return (
          <div className="english-timeline eng_standard-year11">
            <div className="english-timeline-item left">
              <div className="english-timeline-content">
              <h3>Reading to Write</h3>
                <p>Close Reading and Analysis of Multiple Texts</p>
                <p>Evaluate Why Texts Convey Complex Ideas</p>
                <p>Broadening Vocabulary and Expression</p>
              </div>
              <div className="english-timeline-icon">
                <p>1</p>
              </div>
            </div>
            <div className="english-timeline-item right">
              <div className="english-timeline-content">
                <h3>Contemporary Possibilities</h3>
                <p>Digital, Multimodal, and Non-linear Texts</p>
                <p>Power of Communication in Reaching Audiences</p>
              </div>
              <div className="english-timeline-icon">
                <p>2</p>
              </div>
            </div>
            <div className="english-timeline-item left">
              <div className="english-timeline-content">
                <h3>Close Study of Literature</h3>
                <p>Indepth Analysis of Prescribed Literary Text</p>
                <p>Developing Interpretations</p>
                <p>Judgement on Conveying Meaning</p>
              </div>
              <div className="english-timeline-icon">
                <p>3</p>
              </div>
            </div>
            
          </div>
        );
      } else if (activeSubTab === 'year12') {
        return (
          <div className="english-timeline eng_standard-year12">
            <div className="english-timeline-item left">
              <div className="english-timeline-content">
              <h3>Texts and Human Experiences</h3>
                <p>Short Response Questions</p>
                <p>Emotions, Memory, and Experience Essay</p>
              </div>
              <div className="english-timeline-icon">
                <p>1</p>
              </div>
            </div>
            <div className="english-timeline-item right">
              <div className="english-timeline-content">
                <h3>Language, Identity and Culture</h3>
                <p>Written, Spoken, Audio and Visual Texts</p>
                <p>Impact on Shaping a Sense of Identity</p>
                <p>Formation of Cultural Perspectives</p>
              </div>
              <div className="english-timeline-icon">
                <p>2</p>
              </div>
            </div>
            <div className="english-timeline-item left">
              <div className="english-timeline-content">
                <h3>Close Study of Literature</h3>
                <p>Analyse Differing Interpreations of a Text</p>
                <p>Developing Intellectual Significance of a Text</p>
              </div>
              <div className="english-timeline-icon">
                <p>3</p>
              </div>
            </div>
            <div className="english-timeline-item right">
              <div className="english-timeline-content">
              <h3>The Craft of Writing</h3>
                <p>Imaginative, Discursive, or Persuasive Response</p>
                <p>Collaborative Reflection on the Piece</p>
              </div>
              <div className="english-timeline-icon">
                <p>4</p>
              </div>
            </div>
          </div>
        );
      }
    } else if (activeMainTab === 'eng_advanced') {
      if (activeSubTab === 'year11') {
        return (
          <div className="english-timeline eng_advanced-year11">
            <div className="english-timeline-item left">
              <div className="english-timeline-content">
                <h3>Reading to Write</h3>
                <p>Close Reading and Analysis of Multiple Texts</p>
                <p>Evaluate Why Texts Convey Complex Ideas</p>
                <p>Broadening Vocabulary and Expression</p>
              </div>
              <div className="english-timeline-icon">
                <p>1</p>
              </div>
            </div>
            <div className="english-timeline-item right">
              <div className="english-timeline-content">
                <h3>Narratives that Shape our World</h3>
                <p>Contextual Study</p>
                <p>Analysing Differences due to Time Periods</p>
                <p>Comparative Essay Writing</p>
              </div>
              <div className="english-timeline-icon">
                <p>2</p>
              </div>
            </div>
            <div className="english-timeline-item left">
              <div className="english-timeline-content">
                <h3>Critical Study of Literature</h3>
                <p>Comparative Study of Two Texts</p>
                <p>Critical Reflection on Reading Process</p>
              </div>
              <div className="english-timeline-icon">
                <p>3</p>
              </div>
            </div>
           
          </div>
        );
      } else if (activeSubTab === 'year12') {
        return (
          <div className="english-timeline eng_advanced-year12">
            <div className="english-timeline-item left">
              <div className="english-timeline-content">
                <h3>Texts and Human Experiences</h3>
                <p>Short Response Questions</p>
                <p>Emotions, Memory, and Experience Essay</p>
              </div>
              <div className="english-timeline-icon">
                <p>1</p>
              </div>
            </div>
            <div className="english-timeline-item right">
              <div className="english-timeline-content">
                <h3>Textual Conversations</h3>
                <p>Comparative Study of Two Texts</p>
                <p>Developing Personal Perspective</p>
               
              </div>
              <div className="english-timeline-icon">
                <p>2</p>
              </div>
            </div>
            <div className="english-timeline-item left">
              <div className="english-timeline-content">
                <h3>Critical Study of Literature</h3>
                <p>Analysis of Substantial Literary Text</p>
                <p>Interpretation of Content and Language</p>
              </div>
              <div className="english-timeline-icon">
                <p>3</p>
              </div>
            </div>
            <div className="english-timeline-item right">
              <div className="english-timeline-content">
                <h3>The Craft of Writing</h3>
                <p>Imaginative, Discursive, or Persuasive Response</p>
                <p>Collaborative Reflection on the Piece</p>
                
              </div>
              <div className="english-timeline-icon">
                <p>4</p>
              </div>
            </div>
          </div>
        );
      }
    } else if (activeMainTab === 'eng_extension1') {
      if (activeSubTab === 'year11') {
        return (
          <div className="english-timeline eng_extension1-year11">
            <div className="english-timeline-item left">
              <div className="english-timeline-content">
                <h3>Texts, Culture and Value</h3>
                <p>Relationship Between Text and its Culture</p>
                <p>Explore Different Ways of Responding</p>
                <p>Original and Later Manifestations</p>
              </div>
              <div className="english-timeline-icon">
                <p>1</p>
              </div>
            </div>
            <div className="english-timeline-item right">
              <div className="english-timeline-content">
                <h3>Related Project</h3>
                <p>independent investigation</p>
                <p>Select a Key Text</p>
                <p>Evaluate Selected Text in Other Contexts</p>
              </div>
              <div className="english-timeline-icon">
                <p>2</p>
              </div>
            </div>
            
          </div>
        );
      } else if (activeSubTab === 'year12') {
        return (
          <div className="english-timeline eng_extension1-year12">
            <div className="english-timeline-item left">
              <div className="english-timeline-content">
                <h3>Literary Worlds</h3>
                <p>Exploring Text Complexity</p>
                <p>Individual and Collective Considerations</p>
              </div>
              <div className="english-timeline-icon">
                <p>1</p>
              </div>
            </div>
            <div className="english-timeline-item right">
              <div className="english-timeline-content">
                <h3>Electives</h3>
                <p>Literary Homelands</p>
                <p>Worlds of Upheaval</p>
                <p>Reimagined Worlds</p>
                <p>Literary Mindscapes</p>
                <p>Intersecting Worlds</p>
              </div>
              <div className="english-timeline-icon">
                <p>2</p>
              </div>
            </div>
            
          </div>
        );
      }
    } 
  };
  

  return (
    <div className="english-overview-container">
      <div className="english-image-container">
        <img src={englishImage} alt="Subjects" className="english-image" />
        <div className="overlay-english"></div>
        <div className="overlay-text-english">
          <h1>English</h1>
        </div>
      </div>
      <div className="english-book">
        <div className="english-book-content">
          <h2>Book English Tutoring</h2>
          <p className='english-p'>
          At Prime, our dedicated tutors make English both accessible and engaging 
          by connecting students with Sydney-based tutors who create a supportive and inspiring learning environment.
          </p>
          <p className='english-p'>We offer tutoring for all year levels, from Year 7-10 English to the HSC including English Standard, 
            English Advanced, English Extension 1, and English Extension 2. 
          </p> 
          <p className='english-p'>Additionally, our customised resource folders include past exam papers, exemplar essays, and detailed study guides, 
            providing students with all the essential materials needed to succeed.</p>
          
          <a href="/book">
    <button>Book Tutoring</button>
    </a>
        </div>
      </div>
      <div className='english-statement'>
        <h1 className='english-final'>"The analysis of literary works and uncovering their profound meaning is a deeply rewarding endeavor. 
            This is why my goal is to inspire students to think critically and appreciate the significance behind each element, allowing English 
            to be both engaging and enriching.”</h1>
        <p className='english-name'>Berwyn Zhu | English Tutor</p>
      </div>
      <div className='english-bg'>
        <h1 className="english-title">Course Overview</h1>
        <p className="english-subtitle">Select tab to pick year</p>
        <div className="english-main-tabs">
          <button className={`english-main-tab-button ${activeMainTab === 'eng_standard' ? 'active' : ''}`} onClick={() => handleMainTabClick('eng_standard')}>English Standard</button>
          <button className={`english-main-tab-button ${activeMainTab === 'eng_advanced' ? 'active' : ''}`} onClick={() => handleMainTabClick('eng_advanced')}>English Advanced</button>
          <button className={`english-main-tab-button ${activeMainTab === 'eng_extension1' ? 'active' : ''}`} onClick={() => handleMainTabClick('eng_extension1')}>English Extension 1</button>
        </div>
        <div className="english-sub-tabs">
  {activeMainTab !== 'extension2' && (
    <button className={`english-tab-button ${activeSubTab === 'year11' ? 'active' : ''}`} onClick={() => handleSubTabClick('year11')}>Year 11</button>
  )}
  <button className={`english-tab-button ${activeSubTab === 'year12' ? 'active' : ''} ${activeMainTab === 'extension2' ? 'extension2-year12-button' : ''}`} onClick={() => handleSubTabClick('year12')}>Year 12</button>
</div>


        <div className={`english-tab-content ${activeSubTab === 'year11' || activeSubTab === 'year12' ? 'active' : ''}`}>
          {renderContent()}
        </div>
      </div>
    </div>
  );
};

export default English;
