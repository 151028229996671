import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header.js';
import Footer from './components/Footer.js';
import PrivacyPolicy from './components/PrivacyPolicy.js';

import Contact from './components/Contact.js';
import FAQ from './components/FAQ/FAQ.js';
import Pricing from './components/price/Price.js';
import Book from './components/book/Book.js';
import './App.css';

import About from './components/about us/About.js';
import CompanyProfile from './components/about us/Profile.js';

import Home from './components/homepage/Home.js';
import Face from './components/homepage/Face.js';
import Online from './components/homepage/Online.js';

import Subjects from './components/subjects/Subjects.js'
import Physics from './components/subjects/Physics.js';
import Chemistry from './components/subjects/Chemistry.js';
import Biology from './components/subjects/Biology.js';
import Mathematics from './components/subjects/Mathematics.js';
import English from './components/subjects/English.js';
import Geography from './components/subjects/Geography.js';
import Economics from './components/subjects/Economics.js';
import History from './components/subjects/History.js';


function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/face-to-face-tutoring" element={<Face />} />
            <Route path="/online-tutoring" element={<Online />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/about-us" element={<About />} />
            <Route path="/about-us/company-profile" element={<CompanyProfile />} /> 
            <Route path="/FAQs" element={<FAQ />} />
            <Route path="/pricing" element={<Pricing />} />
           
            <Route path="/book" element={<Book />} />
            

            <Route path="/subjects" element={<Subjects />} /> 
            <Route path="/subjects/physics" element={<Physics />} /> 
            <Route path="/subjects/chemistry" element={<Chemistry />} />
            <Route path="/subjects/biology" element={<Biology />} />  
            <Route path="/subjects/mathematics" element={<Mathematics />} />
            <Route path="/subjects/english" element={<English />} /> 
            <Route path="/subjects/geography" element={<Geography />} />   
            <Route path="/subjects/economics" element={<Economics />} />
            <Route path="/subjects/history" element={<History />} />

            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
