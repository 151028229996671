import React from 'react';
import '../styles/Footer.css'; 
import { useNavigate, useLocation } from 'react-router-dom';

const Footer = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // Define pages where the promo should be shown
  const promoPages = ['/', '/about-us', '/about-us/company-profile', '/contact', '/pricing', '/face-to-face-tutoring', '/online-tutoring', '/subjects/biology', '/subjects/chemistry', '/subjects/economics', '/subjects/english', '/subjects/geography', '/subjects/history', '/subjects/mathematics', '/subjects/physics'];
  const questionPages = ['/subjects', '/book', '/FAQs', '/privacy-policy'];

  // Check if the current page is in the list of promo pages
  const showPromo = promoPages.includes(location.pathname);
  const showQuestion = questionPages.includes(location.pathname);
  // Determine footer style class based on location
  const getFooterStyleClass = () => {
    switch (location.pathname) {
      case '/':
        return 'home-footer';
      case '/FAQs':
   
        return 'FAQs-footer';
      case '/subjects':
        return 'subjects-footer';
        case '/Pricing':
          return 'pricing-footer';
          case '/book':
            return 'book-footer';
      default:
        return 'default-footer'; // Default style class
    }
  };

  const handleNavigate = () => {
    navigate('/book');
    window.scrollTo(0, 0);
  }

  return (
    <footer className={`footer ${getFooterStyleClass()}`}>
      {showPromo && (
        <div className="footer-promo">
          <h1>Trial a Prime Tuition class for one lesson with no obligation!</h1>
          <p>If you don’t think we’re delivering the best tuition possible, you can walk away for free.</p>
          <li><button className="book" onClick={handleNavigate}>Book Now</button></li>
        </div>
      )}
      {showQuestion && (
        <div className="footer-question">
          <h1>Do You Have A Question?</h1>
          <p>If you have a question that has not been answered here, please don’t hesitate to get in touch through the form below, or feel free to give us a call on 0452 142 216.</p>
          <li><button className="book" onClick={handleNavigate}>Get In Touch</button></li>
        </div>
      )}
      <div className="footer-content">
        <div className="footer-content-width"> 
          <div className="footer-section">
            <h4>About Prime Tuition</h4>
            <ul>
              <li><a href="/about-us/company-profile">Company Profile</a></li>
              <li><a href="/about-us">Why Choose Prime</a></li>
              <li><a href="/FAQs">Frequently Asked Questions</a></li>
            </ul>
          </div>
          <div className="footer-section">
            <h4>Subjects</h4>
            <ul className="subjects-list">
              <li><a href="/subjects/chemistry">Chemistry</a></li>
              <li><a href="/subjects/biology">Biology</a></li>
              <li><a href="/subjects/physics">Physics</a></li>
              <li><a href="/subjects/mathematics">Mathematics</a></li>
              <li><a href="/subjects/economics">Economics</a></li>
              <li><a href="/subjects/english">English</a></li>
              <li><a href="/subjects/geography">Geography</a></li>
              <li><a href="/subjects/history">History</a></li>
            </ul>
          </div>
          <div className="footer-section">
            <h4>More from Prime Tuition</h4>
            <ul>
              <li><a href="/pricing">Pricing</a></li>
              <li><a href="/contact">Contact Us</a></li>
            </ul>
          </div>
        </div> 
      </div>
      <div className="footer-location">
        <span className='footer-icon'>
          <svg fill="#ffffff" width="24" height="24" viewBox="0 0 24 24">
            <path d="M22,5V9L12,13,2,9V5A1,1,0,0,1,3,4H21A1,1,0,0,1,22,5ZM2,11.154V19a1,1,0,0,0,1,1H21a1,1,0,0,0,1-1V11.154l-10,4Z"> </path>
          </svg>
          info@primetuition.org
        </span>
        <span className='footer-icon'>
          <svg fill="#ffffff" width="24" height="24" viewBox="0 0 35 35">
            <path fill="#00000" d="M34.06 26.407l-3.496-3.496a4.942 4.942 0 0 0-8.34 2.528c-5.765-1.078-11.372-6.662-11.721-11.653a4.908 4.908 0 0 0 2.586-1.36a4.943 4.943 0 0 0 0-6.99L9.594 1.94a4.943 4.943 0 0 0-6.99 0C-7.882 12.426 23.574 43.882 34.06 33.396a4.944 4.944 0 0 0 0-6.989z"></path>
          </svg>
          (+61) 452 142 216
        </span>
      </div>
      <div className="footer-social">
        <a href="https://www.facebook.com/profile.php?id=61565950077366" target="_blank" rel="noopener noreferrer">
          <i className="fab fa-facebook"></i>
        </a>
        <a href="https://www.instagram.com/prime_tuition/?utm_source=ig_web_button_share_sheet" target="_blank" rel="noopener noreferrer">
          <i className="fab fa-instagram"></i>
        </a>
        <a href="https://www.tiktok.com/@prime_tuition?is_from_webapp=1&sender_device=pc" target="_blank" rel="noopener noreferrer">
          <i className="fab fa-tiktok"></i>
        </a>
      </div>
      <div className="footer-legal">
        
        <p>© 2024 Prime Tuition Australia. All Rights Reserved.</p>
        <li><a href="/privacy-policy">Privacy Policy</a></li>
      </div>
      
    </footer>
  );
}

export default Footer;
