import React from 'react';
import '../../styles/bookstyle/Steps.css';

const Steps = () => {
  return (
    <div className="steps-container">
     
      <p className="steps-intro">Our booking process is super easy and will just take a few minutes:</p>
      <div className="steps-content">
        <div className="step">
          <div className="step-number">1</div>
          <h2>You book.</h2>
          <p>Let us know about you, your child and how we can help.</p>
          <p>We want to learn as much as we can about your child and what has brought you to us - the more detailed you can be, the better our tutor match can be!</p>
          <p><strong>No payment details are required upfront</strong> - this is all discussed after the first lesson.</p>
        </div>
        <div className="step">
          <div className="step-number">2</div>
          <h2>We match.</h2>
          <p>We talk with our team to organise a tutor that best matches your child's needs.</p>
          <p>Once we've confirmed the first lesson, we'll send you through their profile by email and they'll give you a call to introduce themselves.</p>
          <p>We get most students paired up with a tutor within 72 hours, but we'll keep you updated if there are delays.</p>
        </div>
        <div className="step">
          <div className="step-number">3</div>
          <h2>The magic happens.</h2>
          <p>The first lesson arrives!</p>
          <p>This lesson is designed for your tutor to see how your child is currently doing - so they'll come prepared with our first session assessment to go through.</p>
          <p>We'll check in with you after the session - you can schedule in your next lesson and submit payment information easily from there.</p>
        </div>
      </div>
    </div>
  );
};

export default Steps;
