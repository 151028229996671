import React from 'react';
import '../../styles/aboutusstyle/WhyPrime.css'; 

// Importing SVG files as React components
import { ReactComponent as StarIcon } from '../../assets/icons/star.svg';
import { ReactComponent as GlobeIcon } from '../../assets/icons/globe.svg';
import { ReactComponent as GraduationIcon } from '../../assets/icons/graduation.svg';
import { ReactComponent as BooksIcon } from '../../assets/icons/books.svg';

function WhyPrime() {
    return (
        <div className="why-prime-container">
            <h2 className="whytitle">What Sets Prime Apart</h2>
            <div className="features-grid">
                <div className="feature">
                    <StarIcon className="feature-icon1" />
                    <h3>Tailored Tutoring Style</h3>
                    <p>At Prime Tuition, we are dedicated to fostering a comfortable and supportive learning environment that empowers students to thrive. 
                       We tailor our approach to fit each student's unique learning style, helping them absorb information effectively and enjoy the learning process.</p>
                    <p>When matching students with tutors, we collaborate closely with parents and students to understand their 
                       preferences, academic needs, and goals. Should the need arise, we facilitate seamless transitions to ensure the best possible fit. 
                       We deeply value feedback from parents and students, which drives our continuous improvement efforts to achieve positive outcomes and 
                       student success.</p>
                </div>
                <div className="feature">
                    <GlobeIcon className="feature-icon2" />
                    <h3>Online & In Person Learning</h3>
                    <p>Whether you prefer home tutoring in Sydney or online sessions, Prime Tuition is equipped to accommodate your learning preferences.
                       Our online tutoring sessions utilise platforms like Zoom or Google Meet, featuring interactive tools such as virtual whiteboards for 
                       collaborative note-taking and idea sharing.</p>                     
                    <p>For face-to-face tutoring, we can meet you at your home or a convenient public location such as a local library. 
                       Even if you choose in-person tutoring, we offer the flexibility of switching to online sessions if needed due to illness 
                       or other circumstances. Just inform us, and we'll ensure your upcoming lesson is seamlessly prepared in an online format.</p>   
                </div>
                <div className="feature">
                    <GraduationIcon className="feature-icon3" />
                    <h3>Expert Tutors</h3>
                    <p>At Prime Tuition, our expert team of tutors (95+ ATAR) specialises in subjects ranging from kindergarten to year 12. 
                       Whether you're seeking a tutor in Physics, Chemistry, Maths, English, or any other HSC subject, we have the expertise to meet your needs. 
                       We offer comprehensive tutoring across primary and high school subjects, including NAPLAN and selective school preparation.
                       With our tutors' diverse skills and experience, we ensure students excel in achieving their academic goals.</p>
                </div>
                <div className="feature">
                    <BooksIcon className="feature-icon4" />
                    <h3>High Quality Resources</h3>
                    <p>At Prime Tuition, each student receives a comprehensive folder filled with valuable resources tailored to their specific subject 
                       of tutoring. These resources include past exam papers, which are essential for practicing exam-style questions and gaining familiarity
                       with exam formats. </p>
                    <p>Students also benefit from sample essays and exam responses, which help them understand effective structure and the 
                       expected standards for exam success. Additionally, our subject-specific syllabus notes are customised to align with school curriculums, 
                       ensuring students stay on track with their classroom learning and deepen their subject understanding.</p>
                </div>
            </div>
        </div>
    );
}

export default WhyPrime;
