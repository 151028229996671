import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import PrimeImage from '../../assets/prime_about.png';
import '../../styles/aboutusstyle/About.css';
import WhyPrime from './WhyPrime';

function AboutPrime() {
  const navigate = useNavigate();

  const handleLearnMoreClick = () => {
    navigate('/about-us/company-profile');
    window.scrollTo(0, 0);
  };

  return (
    <div className="about-prime">
      <Helmet>
      <title>About Us | Private Tutoring Sydney | Prime Tuition</title>
        <meta
          name="description"
          content="Prime Tuition, founded by Anuj Savai, offers personalized one-to-one tutoring services in Sydney, Australia. We foster a love for learning and provide tailored educational experiences."
        />
        <link rel="canonical" href="https://primetuition.org/about-us" />
        <meta charset="utf-8" />
        <meta name="keywords" content="private tutoring, one-to-one tutoring, Prime Tuition, Sydney tutoring, personalized education" />
      </Helmet>


      <div className="image-container-about">
        <img src={PrimeImage} alt="About Prime Tuition" className="about-image" />
        <div className="overlay-text-about">
          <h1>About Prime Tuition</h1>
        </div>
      </div>
      <div className="about-content">
        <h2>Company Profile</h2>
        <p>
          Prime Tuition was founded by Anuj Savai, who holds a deep commitment to fostering a love for learning among students.
          Each of our tutors is selected and trained to provide personalised, engaging educational experiences that cater to the unique needs of each student.
        </p>
        <p>
          We believe in making learning a joyous and enriching experience that inspires continual growth and development.
          We tailor our sessions to align with individual learning styles and goals, ensuring each student not only achieves academic success
          but also gains confidence and a deeper understanding of their subjects.
        </p>
        <button className="learn-more-button" onClick={handleLearnMoreClick}>Learn More</button>
      </div>
      <WhyPrime />
    </div>
  );
}

export default AboutPrime;
