import React from 'react';
import { Helmet } from 'react-helmet';
import '../../styles/aboutusstyle/Profile.css';
import ProfileImage from '../../assets/building.jpg';
import { ReactComponent as TargetIcon } from '../../assets/target.svg'; // Ensure the path to your SVG is correct
import { ReactComponent as VisionIcon } from '../../assets/vision.svg';
import { ReactComponent as TutorIcon } from '../../assets/tutor.svg';

function CompanyProfile() {
    return (
        <div className="company-profile">
            <Helmet>
                <title>Company Profile | Private Tutor Near Me | Prime Tuition</title>
                <meta 
                    name="description" 
                    content="Learn about Prime Tuition's mission, vision, and highly qualified tutors. Our goal is to empower students through personalized learning, helping them excel academically and achieve their dreams." 
                />
                <link rel="canonical" href="https://primetuition.org/about-us/company-profile" />
                <meta charset="utf-8" />
                <meta 
                    name="keywords" 
                    content="Prime Tuition, tutoring Sydney, personalized tutoring, mission, vision, qualified tutors, private tutoring, academic success" 
                />
            </Helmet>
            <div className="image-container-profile">
                <img src={ProfileImage} alt="Company Profile" className="profile-image" />
                <div className="overlayprofile"></div>
                <div className="overlay-text-profile">
                    <h1>Company Profile</h1>
                </div>
            </div>
            <div className="section mission-section">
                <div className="profile-content">
                    <TargetIcon className="target-icon" />
                    <h2><span className="thin">Our</span> <span className="normal">Mission</span></h2>
                    <h3>Paving The Way To HSC Excellence and Beyond</h3>
                    <p>
                        Prime Tuition’s mission is simple: To help our students utilise their potential so they can pursue their target degrees and dream careers.
                    </p>
                </div>
            </div>
            <div className="section vision-section">
                <div className="profile-content">
                    <VisionIcon className="vision-icon" />
                    <h2><span className="thin">Our</span> <span className="normal">Vision</span></h2>
                    <h3>Empowering Students For Success Through Personalised Learning</h3>
                    <p>
                        We aim to provide exceptional tutoring that caters to the individual needs of each student, from kindergarten through to Year 12.
                        We understand that every student’s educational journey is unique — some may need help catching up, while others might be looking to extend their learning beyond the
                        classroom to achieve academic excellence. Our approach is holistic and adaptive, focusing on strengthening understanding, boosting confidence, and fostering a lifelong love for learning.
                    </p>
                </div>
            </div>
            <div className="section tutor-section">
                <div className="profile-content">
                    <TutorIcon className="tutor-icon" />
                    <h2><span className="thin">Our</span> <span className="normal">Tutors</span></h2>
                    <h3>Personalised Highly Qualified Tutors</h3>
                    <p>
                        Our team consists of passionate tutors with diverse academic backgrounds in English, Mathematics, Sciences, and HSIE.
                        Each tutor is both an expert in their field and a dedicated mentor who believes in the transformative power of education.
                        They are committed to guiding students through their academic challenges and toward their goals.
                    </p>
                    <p>
                        We carefully pair each student with a tutor whose expertise and teaching style align with the student's academic objectives and personality.
                        This personalised approach ensures that learning is effective, enjoyable, and inspiring. Throughout the tutoring process,
                        we continuously monitor progress and adapt our strategies to optimize learning outcomes, ensuring satisfaction for both students and parents alike.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default CompanyProfile;
