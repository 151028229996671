import React from 'react';
import { Helmet } from 'react-helmet';
import '../styles/Contact.css';  // Your CSS file path here
import ContactImage from '../assets/contact.jpg';

const ContactUs = () => {
  const containerStyle = {
    backgroundImage: `url(${ContactImage})`,
  };

  return (
    <div className="contact-container" style={containerStyle}>
      <Helmet>
        <title>Contact Us | Australian Tutoring Company | Prime Tuition</title>
        <meta
          name="description"
          content="We're here to help answer any questions you might have. We look forward to hearing from you!"
        />
        <link rel="canonical" href="https://primetuition.org/contact" />
        <meta charset="utf-8" />
        <meta
          name="keywords"
          content="private tutoring, Sydney tutors, online tutoring, in-person tutoring, HSC tutoring, selective school preparation, NAPLAN tutoring"
        />
      </Helmet>
       <div className="contactus-overlay"></div>
      <div className="contact-header">
        <h1>Contact Us</h1>
        <p>We're here to help answer any questions you might have. We look forward to hearing from you!</p>
      </div>
      <div className="contact-details">
        <div className="contact-method">
   
          <h3>Call Us</h3>
          <p>0452 142 216 </p>
          <p>Office hours: Monday – Friday | 9AM – 9PM AEST</p>
        </div>
        <div className="contact-method">
 
          <h3>Email Us</h3>
          <p>info@primetuition.org</p>
        </div>
        <div className="contact-method">

          <h3>We Come to You</h3>
          <p>All suburbs of New South Wales or Online everywhere</p>
        </div>
        <div className="social-media-links">
        <a href="https://www.facebook.com/profile.php?id=61565950077366" target="_blank" rel="noopener noreferrer">
          <i className="fab fa-facebook"></i>
        </a>
        <a href="https://www.instagram.com/prime_tuition/?utm_source=ig_web_button_share_sheet" target="_blank" rel="noopener noreferrer">
          <i className="fab fa-instagram"></i>
        </a>
        <a href="https://www.tiktok.com/@prime_tuition?is_from_webapp=1&sender_device=pc" target="_blank" rel="noopener noreferrer">
    <i className="fab fa-tiktok"></i>
  </a>
</div>

        <div className="company-info">
          <p>PRIME TUITION AUSTRALIA</p>
          <p>ABN: 12 543 847 023</p>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;

