import React from 'react';
import primaryImg from '../../assets/primary_school.png';
import highSchoolImg from '../../assets/high_school.png';

import '../../styles/homepage style/Services.css';

function Services() {
  return (
    <div className="services-overview">
      <div className="service">
        <img src={primaryImg} alt="Primary School Tutoring" />
        <h3>Primary School Tutoring</h3>
        <p>The early years are crucial for setting a foundation in education.
          At Prime Tuition, we select and train our primary school tutors to effectively engage and inspire young learners.
          Our approach is tailored to meet the unique needs of each child, helping them whether they need to catch up or are seeking further challenges.</p>
        <p>Learning at this age should be fun, and we make it so.
          Our tutors make sessions engaging, which is often so effective that children look forward to their next visit.
          We cover all primary grades and subjects, adhering to national standards to ensure a comprehensive educational experience.</p>
      </div>
      <div className="service">
        <img src={highSchoolImg} alt="High School Tutoring" />
        <h3>High School Tutoring</h3>
        <p>HSC, NAPLAN and even the IB – we have tutors that specialise in all High School subjects.</p>
        <p>For students in years 7-10, having a mentor to guide them is invaluable during these important years where students can start to fulfull their potential.</p>
        <p>Senior students benefit from having a tutor in year 11 or year 12 who supports them beyond just academic knowledge,
          helping them navigate the stresses of their final school years and achieving success in the HSC.</p>
      </div>
    </div>
  );
}

export default Services;