import React from 'react';
import '../../styles/pricestyle/Price.css'; // Adjust the path as needed
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function PricingSection() {
    return (
        <div className="pricing-section">
            <Helmet>
                <title>Affordable Pricing | Private Tutoring Sydney | Prime Tuition</title>
                <meta 
                    name="description" 
                    content="Prime Tuition offers affordable, contract-free one-on-one tutoring services for just $60 per hour online and $70 per hour in-person. No hidden fees, no contracts, and a 100% happiness guarantee." 
                />
                <link rel="canonical" href="https://primetuition.org/pricing" />
                <meta charset="utf-8" />
                <meta 
                    name="keywords" 
                    content="affordable tutoring, one-on-one tutoring, private tuition, online tutoring, in-person tutoring, Prime Tuition pricing, tutoring Sydney" 
                />
            </Helmet>
            
            <h2>Affordable, contract-free and packed full of value</h2>
            <p>Our one-on-one private tuition costs just:</p>
            <div className="pricing-options">
                <div className="pricing-card">
                    <h3>Online</h3>
                    <p className="subheading">ANYWHERE, ANYTIME</p>
                    <p className="price">$60</p>
                    <p className="per-hour">PER HOUR LESSON</p>
                    
                    <a href="/book">
                    <Link to ="/book" className="pricing-button">BOOK YOUR FIRST LESSON NOW</Link>
                    </a>
                </div>
                <div className="pricing-card">
                    <h3>In Your Home</h3>
                    <p className="subheading">IN-PERSON LESSONS</p>
                    <p className="price">$70</p>
                    <p className="per-hour">PER HOUR LESSON</p>
                    <a href="/book">
                    <Link to ="/book" className="pricing-button">BOOK YOUR FIRST LESSON NOW</Link>
                    </a>
                    
                </div>
            </div>
            <div className="pricing-info">
                <p>✓ First lesson covered by our 100% happiness guarantee – if you aren’t happy after the first lesson it’s on us</p>
                <p>✓ No contracts, prepayments or hidden fees – the price you see above is what you will pay</p>
                <p>✓ The world’s easiest payment system – your payment information is stored securely and charged 24 hours after each lesson</p>
                <p>✓ No charge for missed lessons – just give your tutor at least 24 hours notice before a scheduled lesson</p>
            </div>
        </div>
    );
}

export default PricingSection;
