import React, { useState, useEffect } from 'react';
import '../../styles/homepage style/Face.css';
import InPerson from "../../assets/writing.jpg"
import { Helmet } from 'react-helmet';

function FacePrime() {
 
  useEffect(() => {
    // Scroll to top on initial load
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="face-prime">
        <Helmet>
        <title>Face-to-Face Tutoring | Private Tutoring Sydney | Prime Tuition</title>
        <meta 
          name="description" 
          content="Prime Tuition offers face-to-face private tutoring for students in Sydney. Our experienced tutors come to your home or a nearby library to provide personalized one-on-one lessons that foster deeper learning and engagement." 
        />
        <link rel="canonical" href="https://primetuition.org/face-to-face-tutoring" />
        <meta charset="utf-8" />
        <meta 
          name="keywords" 
          content="face-to-face tutoring, in-home tutoring, private tutoring Sydney, personalized tutoring, Prime Tuition, one-on-one tutoring" 
        />
      </Helmet>
      <div className="image-container-face">
        <img src={InPerson} alt="Face Prime Tuition" className="face-image" />
        <div className="overlay2"></div>
        <div className="overlay-text-face">
          <h1>Face to Face Tutoring</h1>
        </div>
        
      </div>
      <div className="face-content">
        <h2>We Come To You</h2>
        <p>
        For families seeking personalised educational support, our face-to-face private tutoring offers the ideal solution. 
        Whether at your home or a nearby library, our tutors provide focused, one-on-one instruction tailored to your child's unique learning needs. 
        This personalised approach allows students to engage directly with experienced educators, fostering a deeper understanding of the material in a familiar and 
        comfortable environment.
        </p>
        <p>
        Our tutors are carefully selected for their expertise and ability to connect with students, 
        ensuring that each session is both effective and enjoyable. By conducting sessions in a setting that suits your schedule and preferences,
        we eliminate the stress of travel and maximise learning time. Our flexible arrangements allow for lessons to be scheduled at times that work best 
        for your family, providing consistent support throughout the school year.
        </p>
        <p>
        We believe that learning is most effective when it is engaging and interactive. Our tutors use a variety of teaching methods and resources to keep sessions dynamic 
        and responsive to each student's needs. Whether the goal is to improve grades, build confidence, or master a specific subject, our face-to-face tutoring provides the 
        personalized guidance your child needs to succeed.
        </p>
        
      </div>
      
    </div>
  );
}

export default FacePrime;