import React, { useState, useEffect } from 'react';
import '../../styles/subjectsstyle/History.css';
import historyImage from '../../assets/subjects.jpg';

const History = () => {
  
  useEffect(() => {
    // Scroll to top on initial load
    window.scrollTo(0, 0);
  }, []);

  const [activeMainTab, setActiveMainTab] = useState('standard');
  const [activeSubTab, setActiveSubTab] = useState('year11');

  const handleMainTabClick = (mainTab) => {
    setActiveMainTab(mainTab);
    setActiveSubTab(mainTab === 'extension2' ? 'year12' : 'year11'); // Reset to year12 for extension2
  };
  

  const handleSubTabClick = (subTab) => {
    setActiveSubTab(subTab);
  };

  const renderContent = () => {
    if (activeMainTab === 'standard') {
      if (activeSubTab === 'year11') {
        return (
          <div className="history-timeline standard-year11">
            <div className="history-timeline-item left">
              <div className="history-timeline-content">
              <h3>The Nature of Ancient History</h3>
                <p>Ancient Sites and Sources</p>
                <p>Representation of the Ancient Past </p>
                <p>Preservation and Reconstruction of Ancient Sites</p>
                <p>Cultural Heritage and the Role of Museums</p>
                <p>Treatment and Display of Human Remains</p>
              </div>
              <div className="history-timeline-icon">
                <p>1</p>
              </div>
            </div>
            <div className="history-timeline-item right">
              <div className="history-timeline-content">
                <h3>Ancient History Case Studies</h3>
                <p>Two Case Studies</p>
                <p>One from Egypt, Greece, Rome, or Celtic Europe </p>
                <p>One from Australia, Asia, or Americas</p>
              </div>
              <div className="history-timeline-icon">
                <p>2</p>
              </div>
            </div>
            <div className="history-timeline-item left">
              <div className="history-timeline-content">
                <h3>Features of Ancient Societies</h3>
                <p>Slavery</p>
                <p>Art and Architecture </p>
                <p>Weapons and Warfare </p>
                <p>Death and Funerary Customs </p>
                <p>Power and Image  </p>
                <p>Trade and Cultural Contact </p>
              </div>
              <div className="history-timeline-icon">
                <p>3</p>
              </div>
            </div>
            
          </div>
        );
      } else if (activeSubTab === 'year12') {
        return (
          <div className="history-timeline standard-year12">
            <div className="history-timeline-item left">
              <div className="history-timeline-content">
              <h3>Cities of Vesuvius </h3>
                <p>Pompeii and Herculaneum</p>
                <p>Perspectives of Individuals and Groups</p>
                <p>Archaeological and Written Sources</p>
              </div>
              <div className="history-timeline-icon">
                <p>1</p>
              </div>
            </div>
            <div className="history-timeline-item right">
              <div className="history-timeline-content">
                <h3>Ancient Societies</h3>
                <p>Nature of Continuity and Change</p>
                <p>Roles of Historical Figures</p>
                <p>Case Study</p>
              </div>
              <div className="history-timeline-icon">
                <p>2</p>
              </div>
            </div>
            <div className="history-timeline-item left">
              <div className="history-timeline-content">
                <h3>Personalities in their Times</h3>
                <p>Develop Understanding of One Ancient Personality</p>
              
              </div>
              <div className="history-timeline-icon">
                <p>3</p>
              </div>
            </div>
            <div className="history-timeline-item right">
              <div className="history-timeline-content">
              <h3>Historical Periods</h3>
                <p>Archaeological and Written Sources</p>
                <p>Nature of Power and Authority</p>
              </div>
              <div className="history-timeline-icon">
                <p>4</p>
              </div>
            </div>
          </div>
        );
      }
    } else if (activeMainTab === 'advanced') {
      if (activeSubTab === 'year11') {
        return (
          <div className="history-timeline advanced-year11">
            <div className="history-timeline-item left">
              <div className="history-timeline-content">
                <h3>The Nature of Modern History </h3>
                <p>Historic Sites and Sources</p>
                <p>Construction of Modern Histories </p>
                <p>History and Memory</p>
                <p> Representation and Commemoration of the Past. </p>
              </div>
              <div className="history-timeline-icon">
                <p>1</p>
              </div>
            </div>
            <div className="history-timeline-item right">
              <div className="history-timeline-content">
                <h3>Modern History Case Studies</h3>
                <p>Two Case Studies</p>
                <p>One from Australia, Europe, or North America </p>
                <p>One from Asia, Africa, or South America</p>
              </div>
              <div className="history-timeline-icon">
                <p>2</p>
              </div>
            </div>
            <div className="history-timeline-item left">
              <div className="history-timeline-content">
                <h3>The Shaping of the Modern World </h3>
                <p>The Enlightenment</p>
                <p>The French Revolution</p>
                <p>The Age of Imperialism   </p>
                <p>The Industrial Age   </p>
                <p>World War I   </p>
                <p>  The End of Empire </p>
              </div>
              <div className="history-timeline-icon">
                <p>3</p>
              </div>
            </div>
           
          </div>
        );
      } else if (activeSubTab === 'year12') {
        return (
          <div className="history-timeline advanced-year12">
            <div className="history-timeline-item left">
              <div className="history-timeline-content">
                <h3> Power and Authority in the Modern World</h3>
                <p>Rise of Dictatorships after WWI </p>
                <p>The Nazi Regime</p>
                <p>Search for Peace and Security</p>
              </div>
              <div className="history-timeline-icon">
                <p>1</p>
              </div>
            </div>
            <div className="history-timeline-item right">
              <div className="history-timeline-content">
                <h3>National Studies</h3>
                <p>Investigation on One Country</p>
                <p>Period of 20th Century</p>
               
              </div>
              <div className="history-timeline-icon">
                <p>2</p>
              </div>
            </div>
            <div className="history-timeline-item left">
              <div className="history-timeline-content">
                <h3>Peace and Conflict</h3>
                <p>The Cold War</p>
                <p>Conflict in Indochina</p>
                <p>  The Arab–Israeli Conflict  </p>
                <p> Europe, the Pacific, the Gulf  </p>
          
              </div>
              <div className="history-timeline-icon">
                <p>3</p>
              </div>
            </div>
            <div className="history-timeline-item right">
              <div className="history-timeline-content">
                <h3>Change in the Modern World</h3>
                <p>Pro-democracy Movement in Burma </p>
                <p>The Cultural Revolution to Tiananmen Square</p>
                <p>The Civil Rights Movement  </p>
                <p> Changing World Order  </p>
                <p>  Nuclear Age  </p>
              </div>
              <div className="history-timeline-icon">
                <p>4</p>
              </div>
            </div>
          </div>
        );
      }
    } else if (activeMainTab === 'extension1') {
      if (activeSubTab === 'year11') {
        return (
          <div className="history-timeline extension1-year11">
            <div className="history-timeline-item left">
              <div className="history-timeline-content">
                <h3>Texts, Culture and Value</h3>
                <p>Relationship Between Text and its Culture</p>
                <p>Explore Different Ways of Responding</p>
                <p>Original and Later Manifestations</p>
              </div>
              <div className="history-timeline-icon">
                <p>1</p>
              </div>
            </div>
            <div className="history-timeline-item right">
              <div className="history-timeline-content">
                <h3>Related Project</h3>
                <p>independent investigation</p>
                <p>Select a Key Text</p>
                <p>Evaluate Selected Text in Other Contexts</p>
              </div>
              <div className="history-timeline-icon">
                <p>2</p>
              </div>
            </div>
            
          </div>
        );
      } 
    } 
  };
  

  return (
    <div className="history-overview-container">
      <div className="history-image-container">
        <img src={historyImage} alt="Subjects" className="history-image" />
        <div className="overlay-history"></div>
        <div className="overlay-text-history">
          <h1>History</h1>
        </div>
      </div>
      <div className="history-book">
        <div className="history-book-content">
          <h2>Book History Tutoring</h2>
          <p className='history-p'>
          At Prime, our dedicated tutors make History both accessible and engaging 
          by connecting students with Sydney-based tutors who create a supportive and inspiring learning environment.
          We offer Preliminary and HSC tutoring for both Ancient and Modern History, get in touch today to find out how we can assist you
          </p> 
        
          
          <a href="/book">
    <button>Book Tutoring</button>
    </a>
        </div>
      </div>
      <div className='history-statement'>
        <h1 className='history-final'>"History offers a window into the past, helping us understand the present and shape the future. 
            Teaching history is equally rewarding, as I am able to pass on a deeper appreciation for the events and stories that have shaped our world."</h1>
        <p className='history-name'>James Nelson | History Tutor</p>
      </div>
      <div className='history-bg'>
        <h1 className="history-title">Course Overview</h1>
        <p className="history-subtitle">Select tab to pick year</p>
        <div className="history-main-tabs">
          <button className={`history-main-tab-button ${activeMainTab === 'standard' ? 'active' : ''}`} onClick={() => handleMainTabClick('standard')}>Ancient History</button>
          <button className={`history-main-tab-button ${activeMainTab === 'advanced' ? 'active' : ''}`} onClick={() => handleMainTabClick('advanced')}>Modern History</button>
        </div>
        <div className="history-sub-tabs">
  {activeMainTab !== 'extension2' && (
    <button className={`history-tab-button ${activeSubTab === 'year11' ? 'active' : ''}`} onClick={() => handleSubTabClick('year11')}>Year 11</button>
  )}
  <button className={`history-tab-button ${activeSubTab === 'year12' ? 'active' : ''} ${activeMainTab === 'extension2' ? 'extension2-year12-button' : ''}`} onClick={() => handleSubTabClick('year12')}>Year 12</button>
</div>


        <div className={`history-tab-content ${activeSubTab === 'year11' || activeSubTab === 'year12' ? 'active' : ''}`}>
          {renderContent()}
        </div>
      </div>
    </div>
  );
};

export default History;
