import React, { useState } from 'react';
import '../../styles/bookstyle/Form.css';

const Form = () => {
  const [showOtherField, setShowOtherField] = useState(false); // State to control the display of the 'other' input field

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const formProps = Object.fromEntries(formData);

    // Collect multiple selections for types and days
    formProps.type = Array.from(event.target.elements.type)
                          .filter(input => input.checked)
                          .map(input => input.value);
    formProps.days = Array.from(event.target.elements.days)
                          .filter(input => input.checked)
                          .map(input => input.value);

    if (formProps.type.includes('other')) {
      formProps.otherType = event.target.elements.otherType.value; // Capture the 'other' field if it's filled
    }

    try {
      const response = await fetch('https://api.primetuition.org/submit-form', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formProps)
      });
      if (response.ok) {
        alert('Form submitted successfully');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleCheckboxChange = event => {
    setShowOtherField(event.target.checked); // Update state based on checkbox
  };

  return (
    <div className="form-container">
      <div className="form-content">
      <h2>Get in Touch</h2>
      <form id="contactForm" onSubmit={handleSubmit}>
        <div className="form-group row">
          <input type="text" name="parentFirstName" placeholder="Parent/Carer's First Name"/>
          <input type="text" name="parentLastName" placeholder="Parent/Carer's Last Name"/>
        </div>
        <div className="form-group row">
          <input type="text" name="phoneNumber" placeholder="Phone Number*" required />
          <input type="email" name="email" placeholder="Email Address*" required />
        </div>
        <div className="form-group">
          <select name="who" required>
            <option value="" disabled selected>Who is the tutoring for?*</option>
            <option value="child">My Child/Children</option>
            <option value="myself">Myself</option>
            <option value="someone_else">Someone Else</option>
          </select>
        </div>
        <div className="form-group row">
          <input type="text" name="studentFirstName" placeholder="Student's First Name*" required />
          <input type="text" name="studentLastName" placeholder="Student's Last Name*" required />
        </div>
        <div className="form-group row">
          <input type="text" name="studentemail" placeholder="Student's Email Address*" required />
          <select name="studentGrade" required>
            <option value="" disabled selected>Student's Grade*</option>
            <option value="kindergarten">Kindergarten</option>
            <option value="1">Year 1</option>
            <option value="2">Year 2</option>
            <option value="3">Year 3</option>
            <option value="4">Year 4</option>
            <option value="5">Year 5</option>
            <option value="6">Year 6</option>
            <option value="7">Year 7</option>
            <option value="8">Year 8</option>
            <option value="9">Year 9</option>
            <option value="10">Year 10</option>
            <option value="11">Year 11</option>
            <option value="12">Year 12</option>
          </select>
        </div>
        <div className="form-group">
          <label>Types of Tutoring Required?* (Select 1 or Multiple)</label>
          <div className="checkbox-group">
            <label>
              <input type="checkbox" name="type" value="maths" /> Maths Tutoring
            </label>
            <label>
              <input type="checkbox" name="type" value="english" /> English Tutoring
            </label>
            <label>
              <input type="checkbox" name="type" value="science" /> Science Tutoring
            </label>
            <label>
              <input type="checkbox" name="type" value="business" /> Business Studies Tutoring
            </label>
            <label>
              <input type="checkbox" name="type" value="economics" /> Economics Tutoring
            </label>
            <label>
              <input type="checkbox" name="type" value="geography" /> Geography Tutoring
            </label>
            <label>
              <input type="checkbox" name="type" value="history" /> History Tutoring
            </label>
            <label>
              <input type="checkbox" name="type" value="naplan" /> Naplan Preparation
            </label>
            <label>
              <input type="checkbox" name="type" value="selective" /> Selective School Preparation
            </label>
            <label>
              <input type="checkbox" name="type" value="other" onChange={handleCheckboxChange} /> Other
            </label>
            {showOtherField && (
              <input type="text" name="otherType" placeholder="Please specify" />
            )}
          </div>
        </div>
        <div className="form-group">
          <select name="mode" required>
            <option value="" disabled selected>Face-to-Face or Online?*</option>
            <option value="face_to_face">Face-to-Face</option>
            <option value="online">Online</option>
          </select>
        </div>
        <div className="form-group">
          <label>Available Days*</label>
          <div className="checkbox-group">
            <label>
              <input type="checkbox" name="days" value="monday" /> Monday
            </label>
            <label>
              <input type="checkbox" name="days" value="tuesday" /> Tuesday
            </label>
            <label>
              <input type="checkbox" name="days" value="wednesday" /> Wednesday
            </label>
            <label>
              <input type="checkbox" name="days" value="thursday" /> Thursday
            </label>
            <label>
              <input type="checkbox" name="days" value="friday" /> Friday
            </label>
            <label>
              <input type="checkbox" name="days" value="saturday" /> Saturday
            </label>
            <label>
              <input type="checkbox" name="days" value="sunday" /> Sunday
            </label>
          </div>
        </div>
        <div className="form-group">
          <select name="time" required>
            <option value="" disabled selected>Preferred Time?*</option>
            <option value="morning">Morning</option>
            <option value="afternoon">Afternoon</option>
            <option value="evening">Evening</option>
          </select>
        </div>
        <div className="form-group row">
          <input type="text" name="details" placeholder="What can we help with?"/>
         
        </div>
        <button className='form-button' type="submit">Submit</button>
      </form>
      </div>
    </div>
  );
};

export default Form;
