import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Testimonials from './Testimonials';
import BenefitsSection from './BenefitsSection';
import Services from './Services.js';
import Boost from './Boost.js';

import '../../styles/homepage style/Home.css';

function Home() {

  const handleScrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="home">
      <Helmet>
        <title>Prime Tuition | Private Tutoring Sydney</title>
        <meta
          name="description"
          content="Prime Tuition offers personalized private online and in-person tutoring in Sydney. Our highly qualified tutors cover all subjects from Kindergarten to Year 12, including HSC, NAPLAN, and selective school test preparation."
        />
        <link rel="canonical" href="https://primetuition.org/" />
        <meta charset="utf-8" />
        <meta
          name="keywords"
          content="private tutoring, Sydney tutors, online tutoring, in-person tutoring, HSC tutoring, selective school preparation, NAPLAN tutoring"
        />
      </Helmet>
      <div className="screen1">
        <Boost />
        <Testimonials />
      </div>
      <div className="call-to-action">
        <h1>HIGHLY QUALIFIED & EXPERIENCED TUTORS IN SYDNEY</h1>
        <p className='body'>
          At Prime Tuition, we boast a team of accomplished tutors skilled in guiding students from Kindergarten to Year 12 across all primary and high-school subjects, including HSC, NAPLAN, and selective school test preperation.
        </p>
        <p className='body2'>
          We provide both private online and in-person tutoring tailored to meet individual academic needs. Our mission is to create a personalised and engaging tutoring experience that not only meets academic goals but also boosts student confidence.
        </p>
        <p className='body3'>
          Prime Tuition features a unique tutor matching program to ensure a perfect fit for each student, complete with a comprehensive resource folder to support their learning journey. Contact us today to learn more about how we can help enhance your child's educational experience!
        </p>
        <Link to="/book" className="book-button" onClick={handleScrollToTop}>
          Book a Tutor Today
        </Link>
      </div>
      <BenefitsSection />
      <Services />
    </div>
  );
}

export default Home;
